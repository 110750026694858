/* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,500,500i,700&display=swap')
@import url("https://fonts.googleapis.com/css?family=Amiri:400,400i,700,700i&display=swap")

@import '~@angular/material/theming'
@include mat-core()

$primary: mat-palette($mat-orange, 200)
$accent: mat-palette($mat-blue-gray, 600)
$warn: mat-palette($mat-red, 600)
$light-theme: mat-light-theme($primary, $accent, $warn)

@include angular-material-theme($light-theme)

html,
body
  margin: 0
  font-family: 'Roboto', sans-serif
  background: #f1ebdd
  min-width: 300px

a
  color: rgb(99, 102, 109)
  font-weight: bold

a:hover
  color: rgb(89, 92, 99)
  font-weight: bold

a:active
  color: rgb(60, 62, 66)
  font-weight: bold

/* Prevent scroll bar from appearing when transitioning between tabs */
.mat-tab-body-content
  overflow: hidden !important

/* Force tab group width to 100% */
.mat-tab-group
  width: 100%